<template>
    <div>
        <legal-agreement-popup-component
            v-show="isLegalAgreementModalVisible"
            :is-modal-visible="isLegalAgreementModalVisible"
            @close="showModal"
            @submit="closeModalAndRedirectToHome"
        />
    </div>
</template>

<script>  
import { patchItem, postItem } from "@/../../shared/api/SharedClient"
import { API_CALL_URL_PATHS } from '@/../../shared/constants/constantsGeneral.json'
import UserService from '../../../shared/services/UserService'

const LegalAgreementPopupComponent = () =>
    import('@/components/molecules/LegalAgreementPopupComponent')

export default {
    name: 'LegalAgreements',
    components: {
        LegalAgreementPopupComponent
    },
    data() {
        return {
            userService: new UserService(),
            isLegalAgreementModalVisible: false
        }
    },
    created() {
        this.showModal()
    },
    methods: {
        showModal() {
            this.isLegalAgreementModalVisible = true
        },
        closeModalAndRedirectToHome(legalAgreementConsent) {
            // users are not allowed to close the modal if they don't give consent on legal agreements
            this.isLegalAgreementModalVisible = true

            if (legalAgreementConsent) {
                this.SaveConsentAndCloseModal()
            }
        },
        async SaveConsentAndCloseModal() {
            let response = null

            try {
                try {
                    response = await patchItem(
                        process.env.VUE_APP_IDENTITY_SERVER_URL,
                        API_CALL_URL_PATHS.userConsents,
                        await this.userService.getUserId(),
                        [
                            {
                                op: 'replace',
                                path: 'providerUi',
                                value: true
                            }
                        ],
                        false
                    )
                } catch (error) {
                    response = await postItem(
                        process.env.VUE_APP_IDENTITY_SERVER_URL,
                        API_CALL_URL_PATHS.userConsents,
                        {
                            identityExpressUserId: await this.userService.getUserId(),
                            providerUi: true
                        },
                        false
                    )
                }

                if (!response) {
                    return
                }

                if (response.providerUi === true) {
                    this.isLegalAgreementModalVisible = false
                    this.$router.push('/')
                }
            } catch (error) {
                console.error('Something went wrong while saving user consent', error)
            }
        }
    }
}
</script>